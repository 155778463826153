// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  algolia: {
    key: 'a71ca89aa2436e5175f1e7c61415d069',
    appId: 'C2ARMLMA7T'
  },
  firebaseConfig: {
    apiKey: "AIzaSyBWrhatzVUK3izffS47MQbI7WTNoyrgboM",
    authDomain: "cadtrak-redriver.firebaseapp.com",
    databaseURL: "https://cadtrak-redriver.firebaseio.com",
    projectId: "cadtrak-redriver",
    storageBucket: "cadtrak-redriver.appspot.com",
    messagingSenderId: "439924041044",
    appId: "1:439924041044:web:ccf2329ae86c2ca4"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
